@import '../styles/colors';
@import '../styles/recycle';

.head_autonom{
    @include animatedHead;
}
.oq1{
    top: 30%;
    left: -10%;
    font-size: 1.5rem;
    font-weight: bold;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.2rem;
        top: 30%;
    }
    @media only screen and (max-width: $viewport_m_max){
        font-size: 1rem;
        top: 30%;
    }
    @media only screen and (max-width: $viewport_s_max){
        font-size: 0.8rem;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        font-size: 0.6rem;
    }
    &.visible{
        opacity: 1;
        left: 10%;
        @media only screen and (max-width: $viewport_m_max){
            left: 0%;
        }
    }
}
.oq2{
    top: 50%;
    right: 10%;
    font-size: 1.7rem;
    font-family: Aptos-Serif;
    font-weight: bold;
    text-align: right;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.1rem;
        top: 50%;
    }
    @media only screen and (max-width: $viewport_m_max){
        font-size: 1.1rem;
        transform: translate(0%, -50%);
        top: 50%;
    }
    @media only screen and (max-width: $viewport_s_max){
        font-size: 0.9rem;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        font-size: 0.8rem;
    }
    &.visible{
        opacity: 1;
        right: 10%;
        @media only screen and (max-width: $viewport_m_max){
            right: 0%;
        }
    }
}
.oq3{
    top: 70%;
    left: 0%;
    font-size: 1.8rem;
    font-weight: normal;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.2rem;
        top: 70%;
    }
    @media only screen and (max-width: $viewport_m_max){
        font-size: 1.2rem;
        transform: translate(0%, -100%);
        top: 70%;
    }
    @media only screen and (max-width: $viewport_s_max){
        font-size: 1rem;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        font-size: 0.8rem;
    }
    &.visible{
        opacity: 1;
        left: 10%;
        @media only screen and (max-width: $viewport_m_max){
            left: 0%;
        }
    }
}
.arrow4{
    transform: translate(-50%, 0%);
    position: absolute;
    bottom: 0px;
    width: 120px;
    height:120px;
    @media only screen and (max-width: $viewport_m_max){
        height: 80px;
        width: 100px;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        width: 60px;
        height: 60px;
      }
    &.invisible{
        display: none;
    }
}