@import '../styles/colors';
@import '../styles/recycle';
@import '../styles/breakpoints';

.claim{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    color: $baseColorPrimary1;
    transition: all 0.5s;
    // font-size: 2.4rem;
    font-size: 2rem;
    margin:0;
    padding:0;
    font-weight:bold;
    white-space: nowrap;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.6rem;
    }
    @media only screen and (max-width: $viewport_m_max){
        transform: translate(-50%, -50%) scale(1);
        font-size: 1.5rem;
    }
    &.top {
        top: 20%;
        left: 0%;
        transform: translate(-0%, -0%);
        @media only screen and (max-width: $viewport_m_max){
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
.firstText{
    position:absolute;
    top: 30%;
    left: 20%;
    opacity: 0;
    transition: all 0.5s;
    font-size: 1.6rem;
    line-height: 140%;
    width: 100%;
    font-weight:normal;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.3rem;
    }
    @media only screen and (max-width: $viewport_m_max){
        font-size: 1rem;
        text-align:center;
    }
    @media only screen and (max-width: $viewport_xs_max){
        font-size: 0.8rem;
        text-align:center;
    }
    &.visible {
        opacity: 1;
        left: 0%;
    }
}
.beratung, .operativ, .plugnplay{
    position:absolute;
    transform: translate(-50%, 0%);
    width: 200px;
    height: 200px;
    transition: top 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55), left 0.2s, opacity 0.2s, transform 0.2s ease-out;
    position:absolute;
    top: 60%;
    opacity: 0;
    background: none;
    border:none;
    cursor: pointer;
    padding:0px!important;
    svg{
        width: 200px;
        height: 200px;
    }
    @media only screen and (max-width: $viewport_xl_max){
        width: 150px;
        height: 150px;
        top: 70%;
        svg{
            width: 150px;
            height: 150px;
        }
    }
    @media only screen and (max-width: $viewport_l_max){
        width: 120px;
        height: 120px;
        top: 70%;
        svg{
            width: 120px;
            height: 120px;
        }
    }
    @media only screen and (max-width: $viewport_m_max){
        width: 50px;
        height: 50px;
        top: 70%;
        svg{
            width: 50px;
            height: 50px;
        }
    }
    &:hover{
        background: none;
        transform: translate(-50%, 0%) scale(1.1);
        svg{
            path{
                fill: #000;
            }
        }
    }
    svg{
        path{
            fill: $baseColorPrimary1;
        }
    }
    &.invisible {
        opacity: 1;
        top: 55%;
        @media only screen and (max-width: $viewport_m_max){
            top: 60%;
        }
    }
}
.beratung{
    left: 15%;
    @media only screen and (max-width: $viewport_xl_max){
        left: 20%;
    }
}
.operativ{
    left: 50%;
}
.plugnplay{
    left: 85%;
    @media only screen and (max-width: $viewport_xl_max){
        left: 80%;
    }
}
.arrow1{
    transform: translate(-50%, 0%);
    position: absolute;
    bottom: 0px;
    &.invisible{
        display: none;
    }
}
.arrow2{
    transform: translate(-50%, 0%);
    position: absolute;
    bottom: 0px;
    &.invisible{
        display: block;
    }
}