@import './breakpoints';

// In einer Datei namens _mixins.scss
@mixin animatedHead {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    color: $baseColorPrimary1;
    transition: all 0.5s;
    font-size: 2.4rem;
    margin:0;
    white-space: nowrap;
    opacity: 0;
    font-weight: bold;
    padding:0;
    @media only screen and (max-width: $viewport_l_max){
        font-size: 1.7rem;
    }
    @media only screen and (max-width: $viewport_m_max){
        transform: translate(-50%, -50%) scale(1);
        font-size: 1.1rem;
    }
    @media only screen and (max-width: $viewport_s_max){
        font-size: 1.1rem;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        font-size: 1rem;
    }
    &.visible {
        opacity: 1;
    }
    &.top {
        top: 20%;
        left: 0%;
        transform: translate(-0%, -50%);
        @media only screen and (max-width: $viewport_m_max){
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.hiddenL{
    display:inline;
    @media only screen and (max-width: $viewport_l_max){
        display:none;
    }
}

.linkBeratung,
.linkOperativ,
.linkAutonom{
    position: absolute;
    top: 20%;
    right: 10%;
    transform: translate(0%, -50%);
    transition: all 0.2s;
    opacity: 0;
    white-space: nowrap;
    width: auto;
    @media only screen and (max-width: $viewport_m_max){
        top: 85%;
        width: 100%;
        text-align: center;
        transform: translate(-50%, -100%);
        left: 50%;
    }
    @media only screen and (max-width: $viewport_xxs_max){
        top: 85%;
    }
    &.visible{
        opacity: 1;
        right: 30px;
        @media only screen and (max-width: $viewport_m_max){
            left: 50%;
            top: 85%;
            //transform: translate(-50%, 0%);
        }
    }
}
.question{
    position:absolute;
    color: $baseColorPrimary1;
    opacity: 0;
    transition: all 0.5s;
    line-height:120%!important;
}