@import '../styles/colors';

.link{
  display:inline-block;
  background: $baseColorPrimary1;
  font-size: 20px;
  line-height: 50px;
  height: 50px;
  text-decoration: none;
  color: #fff;
  position: relative;
  padding: 0 10px;
  transition: transform 0.2s;
  &:before{
    content: '';
    width: 30px;
    height: 50px;
    position:absolute;
    left: -29.5px;
    background-image: url('../img/ecke.svg');
    background-repeat:no-repeat;
    background-position: right center;
  }
  &:after{
    content: '';
    width: 30px;
    height: 50px;
    position:absolute;
    right: -29.5px;
    transform: rotate(180deg);
    background-image: url('../img/ecke.svg');
    background-repeat:no-repeat;
    background-position: right center;
  }
  &:hover{
    transform: scale(1.1);
    background: #000;
    color: #fff;
    &:before{
      background-image: url('../img/ecke_black.svg');
      background-repeat:no-repeat;
      background-position: right center;
    }
    &:after{
      transform: rotate(180deg);
      background-image: url('../img/ecke_black.svg');
      background-repeat:no-repeat;
      background-position: right center;
    }
  }
}