
@font-face {
    font-family: 'Aptos';
    src: url('/fonts/Aptos/Aptos.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Aptos';
    src: url('/fonts/Aptos/Aptos-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Aptos-Serif';
    src: url('/fonts/Aptos/Aptos-Serif-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  
  @keyframes backgroundFade {
    from {
      background-color: #001489;
    }
    to {
      background-color: #D5D8EB;
    }
  }
  
  
  
  html {
    font-size:20px;
  }
  
  
.app {
    background-color: #001489;
    animation: backgroundFade 1s ease forwards;
    animation-delay: 1s; /* Verzögert den Start der Animation um 1 Sekunde */
    font-family: 'Aptos';
}