@import '../styles/colors';
@import '../styles/breakpoints';

.section{
    width: 100%;
    > .aaa{
        // padding-bottom:0!important;
        margin:0;
        // position: relative;
        // min-height: 100vh; /* Die Section nimmt mindestens die Höhe des Viewports ein */
        // width: 100vw;
        // border: solid 1px;
    }
}
.inner {
    position: relative;
    height: calc(100vh - 140px); /* Inneres Element füllt den Bildschirm */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: #000;
    // padding-top: 140px;
    width: 1200px;
    margin:0 auto;
    @media only screen and (max-width: $viewport_xl_min){
        margin: 0 2rem;
        width: auto;
    }
}