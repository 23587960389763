.ecke{
    position:fixed;
    right:-20vw;
    bottom:0px;
    width: 20vw;
    transition: all 0.2s;
    opacity: 1;
    @media only screen and (max-width: $viewport_m_max){
        width: 40vw;
        right: -40vw;
    }
    &.visible{
        right:0vw;
        opacity: 1;
    }
    .eckeInner{
        position: absolute;
        bottom:0px;
        svg{
            path{
                fill: $baseColorWhite;
            }
        }
        path{
            fill: $baseColorLight;
        }
    }
    .beratungIcon, .operativIcon, .plugnplayIcon{
        opacity: 0;
        position:absolute;
        bottom: 20%;
        left: 64%;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%) scale(1.3);
        transition: all 1s;
        @media only screen and (max-width: $viewport_l_max){
            width: 80px;
            height: 80px;
        }
        @media only screen and (max-width: $viewport_m_max){
            width: 50px;
            height: 50px;
        }
        svg{
            path{
                fill: $baseColorPrimary1;
            }
        }
        &.visible{
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}