@import '../styles/colors';
@import '../styles/breakpoints';

@keyframes arrowFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.arrow{
    width: 120px;
    height:120px;
    padding:0;
    outline: none;
    background: none;
    border:none;
    overflow:hidden;
    z-index: 8000;
    opacity: 0;
    animation: arrowFade 2s ease-in-out infinite;
    animation-delay: 3s; /* Verzögert den Start der Animation um 1 Sekunde */
    background: none!important;
    @media only screen and (max-width: $viewport_m_max){
        height: 80px;
        width: 100px;
    }
    @media only screen and (max-width: $viewport_xxs_max){
      width: 60px;
      height: 60px;
    }
    &:hover{
        cursor: pointer;
        background: none!important;;
    }
    &.invisible{
        display:none;
    }
    svg{
        polygon {
            fill: $baseColorPrimary1;
        }
    }
}